import { useState, useEffect } from 'react';
import {
  IconContext,
  Sun,
  Moon,
  CloudSun,
  CloudMoon,
  Cloud,
  CloudRain,
  CloudLightning,
  Snowflake,
  CloudFog,
} from '@phosphor-icons/react';
import styles from './WeatherWidget.module.css';

// const myLatLng = { lat: 37.511954159182615, lng: -77.43662161539258 };

const KEY = '8dd1cc72d420cb91dc1e270825c61f33';

export default function WeatherWidget() {
  const [temp, setTemp] = useState(0);
  const [weather, setWeather] = useState('');
  const [iconCode, setIconCode] = useState('');

  useEffect(
    function () {
      async function getWeather() {
        const response = await fetch(
          `https://api.openweathermap.org/data/3.0/onecall?lat=37.15&lon=-77.43&appid=${KEY}`
        );
        const forcast = await response.json();
        // console.log(forcast)
        setTemp(Math.round(((forcast.current.temp - 273.15) * 9) / 5 + 32));
        setWeather(forcast.current.weather[0].main);
        setIconCode(forcast.current.weather[0].icon);
        // console.log(temp, weather, iconCode);
      }
      getWeather();
    },
    [iconCode, temp, weather]
  );

  return (
    <IconContext.Provider
      value={{
        color: '#2419b2',
        size: 24,
        weight: 'bold',
      }}
    >
      <div className={styles.weather}>
        {temp}&deg;
        {iconCode === '01d' && <Sun />}
        {iconCode === '01n' && <Moon />}
        {iconCode === ('02d' || '03d') && <CloudSun />}
        {iconCode === ('02n' || '03n') && <CloudMoon />}
        {iconCode === ('04d' || '04n') && <Cloud />}
        {iconCode === ('09d' || '10d') && <CloudRain />}
        {iconCode === '11d' && <CloudLightning />}
        {iconCode === '13d' && <Snowflake />}
        {iconCode === '50d' && <CloudFog />}
      </div>
    </IconContext.Provider>
  );
}

/* 
 (K - 273.15) * 9/5 + 32

{iconCode === '01d' && <Sun size={32} />}
{iconCode === '01n' && <Moon size={32} />}
{iconCode === ('02d' || '03d') && <CloudSun size={32} />}
{iconCode === ('02n' || '03n') && <CloudMoon size={32} />}
{iconCode === ('04d' || '04n') && <Cloud size={32} />}
{iconCode === ('09d' || '10d') && <CloudRain size={32} />}
{iconCode === '11d' && <CloudLightning size={32} />}
{iconCode === '13d' && <Snowflake size={32} />}
{iconCode === '50d' && <CloudFog size={32} />} 

if (iconCode === '01d') setIcon(<Sun />);
if (iconCode === '01n') setIcon(<Moon />);
if (iconCode === '02d' || '03d') setIcon(<CloudSun />);
if (iconCode === '02n' || '03n') setIcon(<CloudMoon />);
if (iconCode === '04d' || '04n') setIcon(<Cloud />);
if (iconCode === '09d' || '10d') setIcon(<CloudRain />);
if (iconCode === '11d') setIcon(<CloudLightning />);
if (iconCode === '13d') setIcon(<Snowflake />);
if (iconCode === '50d') setIcon(<CloudFog />);
*/
