import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  PhoneCall,
  Envelope,
  FacebookLogo,
  MapPinArea,
} from '@phosphor-icons/react';
import {
  Bulldozer,
  PaintBucket,
  Warehouse,
} from '@phosphor-icons/react/dist/ssr';
import FeaturedSection from './FeaturedSection';
import Map from './Map';
import Card from './Card';
import Button from './Button';
import styles from './Hero.module.css';

export default function Hero() {
  const navigate = useNavigate();

  const handleClick = function (e, route) {
    e.preventDefault();
    navigate(route);
  };

  return (
    <div>
      <section className={styles.hero}>
        <div className={styles.heroGrid}>
          <div className={styles.heroBox}>
            <div>
              <h1 className={styles.heading}>
                Concrete Products
                <br />
                <span className={styles.headingScript}>That Work!</span>
              </h1>
              <p className={styles.subheading}>
                High quality release powders, integral colors, stains, sealers,
                and more. <br />
                Stamp sets, equipment and tools available for rent. <br />
                Everything you need to bring your plans to life.
              </p>
            </div>

            <div className={styles.gridColumnShift}>
              <div className={`${styles.heroCard} ${styles.cardProducts}`}>
                <div className={styles.heroCardTop}>
                  <PaintBucket className={styles.icon} />
                  <div>Products</div>
                </div>
                <div className={styles.horizontalRule}></div>
                <div className={styles.heroCardBottom}>
                  <Button
                    type={'small'}
                    onClick={e => handleClick(e, '/products')}
                  >
                    Products
                  </Button>
                </div>
              </div>

              <div className={`${styles.heroCard} ${styles.cardRentals}`}>
                <div className={styles.heroCardTop}>
                  <Bulldozer className={styles.icon} />
                  <div>Rentals</div>
                </div>
                <div className={styles.horizontalRule}></div>
                <div className={styles.heroCardBottom}>
                  <Button
                    type={'small'}
                    onClick={e => handleClick(e, '/rentals')}
                  >
                    Rentals
                  </Button>
                </div>
              </div>

              <div className={`${styles.heroCard} ${styles.cardContacts}`}>
                <div className={styles.heroCardTop}>
                  <Warehouse className={styles.icon} />
                  <div>Location</div>
                </div>
                <div className={styles.horizontalRule}></div>
                <div className={styles.heroCardRow}>
                  <Link
                    className={styles.link}
                    to="https://www.google.com/maps/place/Blueline+Decorative+Concrete+Supply/@37.5117771,-77.4352842,15z/data=!4m6!3m5!1s0x89b11130f6301d11:0xa43b4679eeeb27dd!8m2!3d37.5117771!4d-77.4352842!16s%2Fg%2F11v0n_gr6d?hl=en&entry=ttu"
                  >
                    <MapPinArea className={styles.icon} />
                    <div>
                      1120 Gordon Ave
                      <br />
                      Warehouse 12
                      <br />
                      Richmond, VA 23224
                    </div>
                  </Link>
                </div>
                <div className={styles.heroCardRow}>
                  <Link className={styles.link} href="#">
                    <PhoneCall className={styles.icon} />
                    <div>(804) 316-2714</div>
                  </Link>
                </div>
                <div className={styles.heroCardRow}>
                  <Link className={styles.link} href="#">
                    <Envelope className={styles.icon} />
                    <div>sales@buyblueline.net</div>
                  </Link>
                </div>
                <div className={styles.heroCardRow}>
                  <Link
                    className={styles.link}
                    to="https://www.facebook.com/profile.php?id=100093839654871"
                  >
                    <FacebookLogo className={styles.icon} />
                    <div>Facebook</div>
                  </Link>
                </div>
              </div>
            </div>

            <div className={styles.hours}>
              <span>Monday - Friday 7am - 5pm</span>
              <span> | </span>
              <span>Saturday 7am - 12pm</span>
            </div>
          </div>

          {/* Right side of Hero grid */}
          <div className={styles.heroBox}>
            <Card type={'hero'}>
              <Map />
            </Card>
          </div>
        </div>

        {/* Featured partners logos at bottom of Hero */}
        <div className={styles.partners}>
          <FeaturedSection />
        </div>
      </section>
    </div>
  );
}
