import { NavLink } from 'react-router-dom';
import WeatherWidget from './WeatherWidget';
import styles from './AppNav.module.css';

function AppNav({ openNav, handleCloseNav }) {
  return (
    <nav className={`${styles.nav} ${styles[openNav ? 'navOpen' : '']}`}>
      <ul className={styles.list}>
        <li>
          <NavLink
            className={styles.link}
            to="/"
            onClick={() => handleCloseNav()}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            to="/products"
            onClick={() => handleCloseNav()}
          >
            Products
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            to="/rentals"
            onClick={() => handleCloseNav()}
          >
            Rentals
          </NavLink>
        </li>
        <li>
          <NavLink
            className={styles.link}
            to="/gallery"
            onClick={() => handleCloseNav()}
          >
            Gallery
          </NavLink>
        </li>
        <li>
          <NavLink
            className={`${styles.link} ${styles.cta}`}
            to="/#contact-section"
            onClick={() => handleCloseNav()}
          >
            Contact
          </NavLink>
        </li>
        <li>
          <WeatherWidget />
        </li>
      </ul>
    </nav>
  );
}

export default AppNav;
