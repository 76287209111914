import ContactForm from './ContactForm';
import Map from './Map';
import styles from './ContactSection.module.css';

function ContactSection() {
  return (
    <section id="contact-section" className={styles.sectionContact}>
      <div className="container">
        <h2 className="heading-secondary heading-light center-text">
          Send us a message or visit us personally
        </h2>
        <div className={`${styles.contactCards} grid grid--2-cols`}>
          {/* CONTACT FORM CARD */}
          <div className={styles.contactCard}>
            <ContactForm />
            <div className={styles.contactOptionsGrid}>
              <div className={styles.contactOption}>
                <h3 className={styles.contactOptionHeading}>Give us a call</h3>
                <p className={styles.contactText}>
                  804-316-2714
                  <br />
                  Monday - Friday
                  <br />
                  7am - 5pm EST
                </p>
              </div>
              <div className={styles.contactOption}>
                <h3 className={styles.contactOptionHeading}>Send us mail</h3>
                <p className={styles.contactText}>
                  1120 Gordon Ave<br/>Warehouse 12
                  <br />
                  Richmond, VA 23224
                </p>
              </div>
              <div className={styles.contactOption}>
                <h3 className={styles.contactOptionHeading}>Follow us</h3>
                <p className={styles.contactText}>
                  Follow us on Facebook for updates and important news
                </p>
              </div>
            </div>
          </div>

          {/* GOOGLE MAPS CARD */}
          <div className={styles.contactCard}>
            <div className={styles.toggleMap}>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
