import React from 'react';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import styles from './Map.module.css';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const myLatLng = { lat: 37.511954159182615, lng: -77.43662161539258 };
const zoom = 14;

export default function MapComponent() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function onLoad(map) {
    map.setZoom(zoom);
    map.setCenter(myLatLng);
    map.setMapTypeId('roadmap');
    setMap(map);
    // console.log(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const renderMap = () => {
    return (
      <GoogleMap
        id="map"
        // center={myLatLng}
        // zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <OverlayView
          position={myLatLng}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div className={styles.popupContainer}>
            <div className={styles.popupBubbleAnchor}>
              <div id="content" className={styles.popupBubble}>
                <img
                  className={styles.logo}
                  alt="Blueline logo"
                  src="img/blueline-logo-horizontal-plain-black.svg"
                />
              </div>
            </div>
          </div>
        </OverlayView>
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <></>;
}
