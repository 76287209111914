import { useEffect, useRef } from 'react';
import {
  useLocation,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Rentals from './pages/Rentals';
import Products from './pages/Products';
import NoPage from './pages/NoPage';
import Gallery from './pages/Gallery';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
}


const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/rentals',
        element: <Rentals />,
      },
      {
        path: '/products',
        element: <Products />,
      },
      {
        path: '/gallery',
        element: <Gallery />,
      },
      {
        path: '*',
        element: <NoPage />,
      },
    ],
  },
]);

export default function App() {
  return (
    <RouterProvider router={router}>
      <ScrollToTop />
      <ScrollToAnchor />
    </RouterProvider>
  );
}


