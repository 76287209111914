import styles from './Logos.module.css';

function APCLogo() {
  return (
    <img
      className={styles.logo}
      src="img/apc-logo.png"
      alt="Alabama Pigment Company logo"
    />
  );
}

function BluelineLogo() {
  return (
    <img
      className={styles.logo}
      src="img/blueline-logo-horizontal-plain-black.png"
      alt="Blueline logo"
    />
  );
}

function BluelineIcon() {
  return (
    <img
      className={styles.bluelineIcon}
      src="img/blueline-logo-box-outlined.svg"
      alt="Blueline icon"
    />
  );
}

function ConcreteCoatingsLogo() {
  return (
    <img
      className={styles.logo}
      src="img/Concrete-Coatings-Logo.svg"
      alt="Concrete Coatings logo"
    />
  );
}

function SikaLogo() {
  return (
    <img className={styles.logo} src="img/Logo_Sika_AG.svg" alt="Sika logo" />
  );
}

function SurecreteLogo() {
  return (
    <img
      className={styles.logo}
      src="img/surecrete-logo-black.png"
      alt="Surecrete logo"
    />
  );
}

export {
  APCLogo,
  BluelineLogo,
  BluelineIcon,
  ConcreteCoatingsLogo,
  SikaLogo,
  SurecreteLogo,
};
