import { Link } from 'react-router-dom';
import { stamps, equipment } from '../db/rentalData.js';
import Button from '../components/Button';
import Card from '../components/Card';
import styles from './Rentals.module.css';

export default function Rentals() {
  // function RentalCard(props) {
  //   return (
  //     <Card type={'rental'}>
  //       <img className={styles.image} src={props.image} alt={props.name} />
  //       <div className={styles.rentalContainer}>
  //         <h3 className={styles.model}>{props.name}</h3>
  //         <p className={styles.description}>{props.description}</p>
  //         {props.category === 'Stamp Set' ? (
  //           ''
  //         ) : (
  //           <Button
  //             onClick={e => {
  //               e.preventDefault();
  //               window.location.href = `${props.webpage}`;
  //             }}
  //             type={'primary'}
  //           >
  //             <span aria-hidden="true">Manufacturer Details →</span>
  //           </Button>
  //         )}
  //       </div>
  //     </Card>
  //   );
  // }

  return (
    <main className={styles.rentals}>
      <div className="page-header center-text">
        <span className="heading-primary heading-light">
          Rentals
        </span>
      </div>
      <div className="container">
        <h2 className="heading-secondary heading-dark center-text">
          Site Equipment & Tools
        </h2>
        <div className="grid grid--2-cols grid--center-v">
          {equipment.map(item => (
            <Card type={'equipment'} key={item.model}>
              <img className={styles.image} src={item.image} alt={item.name} />
              <div className={styles.rentalContainer}>
                <h3 className={styles.heading}>
                  {`${item.manufacturer} ${item.model}`}
                </h3>
                <p className={styles.subheading}>{item.description}</p>
                <p className={styles.summary}>{item.summary}</p>
                <Link className={styles.link} to={`${item.webpage}`}>
                  Manufacturer Webpage →
                </Link>
              </div>
            </Card>
          ))}
        </div>
      </div>
      <div className="container">
        <h2 className="heading-secondary heading-dark center-text">Stamp Sets & Overlays</h2>
        <div>
          <div className="grid grid--2-cols grid--center-v">
            {stamps.map(set => (
              <Card type={'stamps'} key={set.model}>
                <img className={styles.image} src={set.image} alt={set.name} />
                <div className={styles.rentalContainer}>
                  <h3 className={styles.heading}>{set.name}</h3>
                  <p className={styles.subheading}>{set.category}</p>
                  <ul className={styles.list}>
                    {set.includes.map(item => (
                      <li>{`${item.number} - ${item.type}`}</li>
                    ))}
                  </ul>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

/*

set.includes.map(item => (<li>`${item.number} ${item.type}`</li>))

              <RentalCard
                key={stamp.name}
                name={stamp.name}
                category={stamp.category}
                description={stamp.includes.join(', ')}
                image={stamp.image}
                includes={stamp.includes}
              />

  <Card type={'equipment'} key={item.model}>
    <img className={styles.image} src={item.image} alt={item.name} />
    <div className={styles.rentalContainer}>
      <h3 className="heading-tertiary">{item.name}</h3>
      <p className="subheading">{item.category}</p>
      <p className={styles.summary}>{item.includes}</p>
    </div>
  </Card>

  <Button syles

  const Title = styled.h1
    resolutions 

  helpful helpful
*/
