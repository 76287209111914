const photos = [
  {
    id: 1,
    album: 'samples',
    src: './img/gallery/sample-1 (400x400).jpg',
    alt: 'Compass stamped concrete',
  },
  {
    id: 2,
    album: 'samples',
    src: './img/gallery/sample-2 (400x400).JPEG',
    alt: 'Ashlar stamped driveway',
  },
  {
    id: 3,
    album: 'samples',
    src: './img/gallery/sample-3 (400x400).JPEG',
    alt: 'Ashlar stamped driveway with 11.5 inch woodplank stamped porch',
  },
  {
    id: 4,
    album: 'samples',
    src: './img/gallery/sample-4 (400x400).JPEG',
    alt: 'Ashlar stamped driveway',
  },
  {
    id: 5,
    album: 'samples',
    src: './img/gallery/sample-5 (400x400).JPEG',
    alt: 'Ashlar stamped driveway',
  },
  {
    id: 6,
    album: 'samples',
    src: './img/gallery/sample-6 (400x400).JPEG',
    alt: 'Ashlar stamped sidewalk',
  },
  {
    id: 7,
    album: 'samples',
    src: './img/gallery/sample-7 (400x400).JPEG',
    alt: 'Bluestone stamped driveway with bluestone border',
  },
  {
    id: 8,
    album: 'samples',
    src: './img/gallery/sample-8 (400x400).JPEG',
    alt: 'Bluestone stamped driveway with ashlar stamped easement',
  },
  {
    id: 9,
    album: 'samples',
    src: './img/gallery/sample-9 (400x400).JPEG',
    alt: '11.5inch woodplank stamped porch',
  },
  {
    id: 10,
    album: 'samples',
    src: './img/gallery/sample-10 (400x400).JPEG',
    alt: '11.5inch woodplank stamped porch',
  },
  {
    id: 11,
    album: 'samples',
    src: './img/gallery/sample-11 (400x400).jpg',
    alt: '11.5inch woodplank stamped porch',
  },
  {
    id: 12,
    album: 'samples',
    src: './img/gallery/sample-12 (400x400).jpg',
    alt: 'Ashlar stamped walkway',
  },
  {
    id: 13,
    album: 'samples',
    src: './img/gallery/sample-13 (400x400).jpg',
    alt: 'Ashlar stamped walkway',
  },
  {
    id: 14,
    album: 'samples',
    src: './img/gallery/sample-14 (400x400).jpg',
    alt: 'Ashlar stamped walkway',
  },
  {
    id: 15,
    album: 'samples',
    src: './img/gallery/sample-15 (400x400).jpg',
    alt: 'Ashlar stamped walkway',
  },
  {
    id: 16,
    album: 'samples',
    src: './img/gallery/sample-16 (400x400).jpg',
    alt: 'Ashlar stamped kitchen floor',
  },
  {
    id: 17,
    album: 'samples',
    src: './img/gallery/sample-17 (400x400).jpg',
    alt: 'Custom stamped kitchen floor',
  },
  {
    id: 18,
    album: 'samples',
    src: './img/gallery/sample-18 (400x400).jpg',
    alt: 'Custom stamped kitchen floor',
  },
  {
    id: 19,
    album: 'samples',
    src: './img/gallery/sample-19 (400x400).jpg',
    alt: 'Ashlar stamped kitchen floor',
  },
  {
    id: 20,
    album: 'samples',
    src: './img/gallery/sample-20 (400x400).jpg',
    alt: 'Herringbone brick stamped driveway',
  },
  {
    id: 21,
    album: 'samples',
    src: './img/gallery/sample-21 (400x400).jpg',
    alt: 'Fan stone stamped concrete',
  },
  // {
  //   id: 22,
  //   album: 'samples',
  //   src: './img/gallery/sample-22 (400x400).jpg',
  //   alt: '',
  // },
  {
    id: 23,
    album: 'samples',
    src: './img/gallery/sample-23 (400x400).jpg',
    alt: 'Stamped and sealed driveway and sidewalk',
  },
  {
    id: 24,
    album: 'samples',
    src: './img/gallery/sample-24 (400x400).jpg',
    alt: 'Flagstone stamped patio',
  },
  {
    id: 25,
    album: 'samples',
    src: './img/gallery/sample-25 (400x400).jpg',
    alt: 'Custom stamped kitchen floor',
  },
];

export { photos }