const blueline = [
  {
    product: 'ReleaseMax Colored Release Powder',
    image: './img/blueline/releaseMax-flier.png',
    description: [
      'Blue Line Color ReleaseMax® is a custom blend of color pigments (ASTM-C979), dry hydrophobic powder, and other proprietary ingredients. They are made with Bluelines Accu-Pac® pure, light fast, weather proof pigments. Manufacturing guality and consistency is assured by strict control of blending the components in their proper proportions. Blue Line Release Max® Colored Release Powder adds color variations and highlights to the textured surface, providing an attractive two-tone antiqued look that very closely resembles natural materials such as cobblestone, brick, tile and slate.',
      'The release powder is the highlighter not the primary colorant in the concrete; coloring the concrete may be achieved by using integral pigments or color hardeners. Which procedure to use should be determined by the final desired effect, conditions at the job site and preference of the designer. Integral colored produces a color through matrix while color hardener is a surface application. The advantage of integral color is that it colors through the thickness of the slab, saves time and labor and it makes for a cleaner finishing process. Color hardener on the other hand required more labor and time to finish but it provides with a greater color selection including light hues and pastel colors not possible to achieve with integral color.',
    ],
    features: [
      'Allows a clean bond break between fresh concrete and stamping tools or mats.',
      'Adds color variation-antiquing affect for stamped surfaces.',
      'Easy removal process allows for controlled removal to achieve desired shading effect.',
    ],
    tips: [
      'It is helpful to also coat the stamps with release powder before placing on wet concrete for the first time.',
      'Never drag anything on the surface or trowel over release powder. Discoloration will occur.',
      'If the stamps have been recently washed, they must be fully dry before starting to stamp. Any moisture still left on the mats will decrease the release powder efficiency resulting on concrete adhering to stamp.',
      'Always cover walls, columns and plants with 1 mil plastic liner to avoid excessive clean up labor.',
      'Before pressure washing the concrete, broom off release powder or pick up with a wet/dry vacuum.',
      'If after pressure washing and cleaning, there is still too much release on the surface a very mild water/ muriatic acid solution mav be used to complete the removal. Use this process with caution.',
    ],
    packaging:
      'Blueline ReleaseMax® Colored Release is packaged in 30lb pails. Coverage is approximately 800-1,000 Square Feet per 30lb pail.',
    colors: [
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Buff',
        image: './img/Blueline/buff.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Medium Brown',
        image: './img/Blueline/medium-brown.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Dark Brown',
        image: './img/Blueline/dark-brown.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Derby Red',
        image: './img/Blueline/derby-red.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Terracotta',
        image: './img/Blueline/terracotta.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Charcoal',
        image: './img/Blueline/charcoal.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Pewter',
        image: './img/Blueline/pewter.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Platinum Gray',
        image: './img/Blueline/platinum-gray.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Moss Green',
        image: './img/Blueline/moss-green.png',
      },
      {
        category: 'Colored Release Powder',
        manufacturer: 'Blueline',
        name: 'ReleaseMax',
        color: 'Rustic',
        image: './img/Blueline/rustic.png',
      },
    ],
  },
  {
    product: 'Accu-Pac Integral Colors',
    image: './img/blueline/accu-pac-flier.png',
    description:
      'Iron oxide pigments are in ready mix, pre-cast, tilt up, Decorative concrete, shot crete, mortar, stucco and other cement based applications.',
    materials:
      'Blue Line Accu-Pac® colors do not contain fillers or extenders. All pigments are permanent, light fast, inert and stable to atmospheric conditions. Blue Line Accu-Pac® colors comply with ASTM C979, Pigments for Integrally Colored Concrete. Integral colors are not designed for dust-on application.',
    packaging:
      'Blue Line Accu-Pac® are packaged in pre-measured per cubic yard disintegrating bags. Accu-Pac® may be tossed into mixer without opening or pouring. The mixing action disintegrates the bag allowing the pigment to disperse throughout the mix.',
    mixDesign: [
      'Make sure to use the same mix design and a consistent water to cement ratio throughout the job with a consistent slump between 3" to 5". Color variation may occur if batch proportion and slump are not maintained from load to load. Keep all raw materials (sand, cement and aggregates especially] as consistent as possible throughout the entire project. Changes in raw materials (size, color, moisture content) affect the final color. Remember water has a vast effect on final color. Adding too much water will lighten the color of the project giving it a pale or washed out look. When better flow ability is required, use a plasticizer or water-reducing admixture. Calcium chloride or any chloride based accelerator should not be used. Chloride based additives will cause discoloration in the finished product.',
      'Blue Line Accu-Pac® Integral Color can also be added at the job site. Add bags to drum and mix for 12-15 minutes (120-150 revolutions). Order concrete at a workable slump (3-5") and always keep water addition to a minimum. Make every effort to maintain consistency with multiple loads. Water addition can lead to inconsistent color.',
    ],
    tips: [
      'Blue Line recommends following similar procedures for colored and uncolored concrete regarding base preparation, use of vapor barriers, form placement, reinforcement and joints. Integrally colored concrete should be installed the same way as high quality uncolored concrete. Listed are additional guidelines that should be observed for colored concrete. For more detailed information, please review our color guide.',
      'Avoid adding extra water at the job site. Do not wet finishing tools or add water to the surface of the colored concrete.',
      'When the surface becomes wet, stop floating. Allow bleed water to evaporate, then begin troweling. Waiting too long, may allow dark spots or "burn" marks to appear.',
      'Even curing + even drying = even color.',
      'Pigment loading should never exceed 10% of the weight of the cement content.',
      'Consistency = Reliability.',
      'Do not sprinkle pigment onto the surface of the concrete.',
      'Complete the job by sealing your project with one of Bluelines Provided Sealers',
    ],
    colors: [
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Dusty Rose',
        image: './img/Blueline/acc-dusty-rose.png',
        coating: '1 bag Sedona Rust per 2 CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Sedona Rust',
        image: './img/Blueline/acc-sedona-rust.png',
        coating: '10 lbs per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Chili Pepper',
        image: './img/Blueline/acc-chili-pepper.png',
        coating: '2 bag Sedona Rust per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Irish Coffee',
        image: './img/Blueline/acc-irish-coffee.png',
        coating: '10 lbs per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Khaki',
        image: './img/Blueline/acc-khaki.png',
        coating: '5 lbs per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Silver Dove',
        image: './img/Blueline/acc-silver-dove.png',
        coating: '6 lbs per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Gray Fox',
        image: './img/Blueline/acc-gray-fox.png',
        coating: '10 lbs per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Charcoal',
        image: './img/Blueline/acc-charcoal.png',
        coating: '2 bags Gray Fox per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Midnight',
        image: './img/Blueline/acc-midnight.png',
        coating: '1 bag per CY',
      },
      {
        category: 'Integral Color',
        manufacturer: 'Blueline',
        name: 'Accu-Pac',
        color: 'Buff',
        image: './img/Blueline/acc-buff.png',
        coating: '5 lbs per CY',
      },
    ],
  },
];

const surecrete = [
  {
    product: 'MASTER COLOR CHART',
    image: './img/Surecrete/Master-Color-Chart.png',
    url: 'https://surecretedesign.com/concrete-stain-colors/#c1',
  },
  {
    product: 'INTEGRA-COMPLETE INTEGRAL COLOR CHART',
    image:
      './img/Surecrete/SureCrete-Integra-Complete-Integral-Color-Chart.png',
  },
  {
    product: 'INTEGRA-SELECT INTEGRAL COLOR CHART',
    image: './img/Surecrete/SureCrete-Integra-Select-Integral-Color-Chart.png',
  },
  {
    product: 'VIVID ACID STAIN COLOR CHART',
    image: './img/Surecrete/Vivid-Color-Chart.png',
  },
  {
    product: 'DK FLAKE COLOR CHART',
    image: './img/Surecrete/DK-Flake-Color-Chart.png',
  },
  {
    product: 'TRANS4LOOR METALLIC EPOXY COLOR CHART',
    image: './img/Surecrete/Trans4loor-Metallic-Pigment-Color-Chart.png',
  },
  {
    product: 'XS COLOR AND XS VEINING',
    image: './img/Surecrete/XS-Color-XS-Veining-Color-Chart.png',
  },
  {
    product: 'ECO STAIN COLOR CHART',
    image: './img/Surecrete/eco-stain-color-chart.png',
  },
];

const alabamaPigmentCo = [
  {
    product: 'APC PRECISION-PAC™ INTEGRAL COLORS',
    image: './img/APC/precision-pac-integral-colors-1.png',
    url: '',
  },
  {
    product: 'APC PRECISION COLORED RELEASE POWDER',
    image: './img/APC/precision-colored-release-powder-1.png',
    url: '',
  },
  {
    product: 'APC PRECISION COLOR HARDENERS',
    image: './img/APC/precision-colored-hardeners-1.png',
    url: '',
  },
  {
    product: 'APC PRECISION STAIN',
    image: './img/APC/precision-stain-1.png',
    url: '',
  },
  {
    product: 'NEXTPAC',
    image: './img/APC/next-pac-1.png',
    url: '',
  },
  {
    product: 'HYDRA-TINT',
    image: './img/APC/hydra-tint-1.png',
    url: '',
  },
  {
    product: 'APC PRECISION ACID STAIN',
    image: './img/APC/precision-acid-stain-1.png',
    url: '',
  },
];

export { blueline, surecrete, alabamaPigmentCo };
