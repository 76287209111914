import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import {
  Envelope,
  FacebookLogo,
  MapPinArea,
  PhoneCall,
} from '@phosphor-icons/react/dist/ssr';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Link className={styles.logoLink} to="/">
        <img
          className={styles.logo}
          alt="Blueline logo"
          src="img/blueline-logo-horizontal-plain-black.png"
        />
      </Link>
      <div className={styles.gridFooter}>
        <div className={styles.contactColumn}>
          <Link
            className={styles.link}
            to="https://www.google.com/maps/place/Blueline+Decorative+Concrete+Supply/@37.5117771,-77.4352842,15z/data=!4m6!3m5!1s0x89b11130f6301d11:0xa43b4679eeeb27dd!8m2!3d37.5117771!4d-77.4352842!16s%2Fg%2F11v0n_gr6d?hl=en&entry=ttu"
          >
            <MapPinArea className={styles.icon} weight="bold" />
            1120 Gordon Avenue, Warehouse 12
            <br />
            Richmond, VA 23224
          </Link>
          <div className={styles.contacts}>
            <Link className={styles.link} to="tel:804-316-2714">
              <PhoneCall className={styles.icon} weight="bold" />
              804-316-2714
            </Link>
            <Link className={styles.link} to="/#contact-section">
              <Envelope className={styles.icon} weight="bold" />
              sales@buyblueline.net
            </Link>
            <Link
              className={styles.link}
              to="https://www.facebook.com/profile.php?id=100093839654871"
            >
              <FacebookLogo className={styles.icon} weight="bold" />
              Facebook
            </Link>
          </div>
        </div>

        <nav className={styles.navColumn}>
          <ul className={styles.nav}>
            <li>
              <Link className={styles.link} to="#">
                About
              </Link>
            </li>
            <li>
              <Link className={styles.link} to="/products">
                Products
              </Link>
            </li>
            <li>
              <Link className={styles.link} to="/rentals">
                Rentals
              </Link>
            </li>
            <li>
              <Link className={styles.link} to="/gallery">
                Gallery
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <p className={styles.copyright}>
        Built by Brian Thornton for Blueline Decorative Concrete Supply LLC.
        Copyright &copy; <span className="year">2023</span>
      </p>
    </footer>
  );
}
