import { photos } from '../db/photos.js'
import styles from './Gallery.module.css'

function Gallery() {
  return (
    <main className={styles.gallery}>
      <div className="page-header center-text">
        <span className="heading-primary heading-light">
          Gallery
        </span>
      </div>
      <div className={`${styles.album}`}>
        {photos.map(item => (<img key={item.id} src={item.src} alt={item.alt} />))}
      </div>
    </main>
  );
}

export default Gallery
