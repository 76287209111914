import { useNavigate } from 'react-router-dom';
import { BluelineIcon } from '../components/Logos';
import Button from '../components/Button';

import styles from './NoPage.module.css';

export default function NoPage() {
  const navigate = useNavigate();

  return (
    <section className={styles.noPage}>
      <div className={styles.noPageGrid}>
        <div className={styles.gridCol}>
          <BluelineIcon />
        </div>
        <div className={styles.gridCol}>
          <p className={styles.error}>Oops... 404 Error</p>
          <p className={styles.message}>
            That page was not found, but try exploring our site and you might
            find what you are looking for.
          </p>
          <div className="no-page-btns">
            <Button
              onClick={e => {
                e.preventDefault();
                navigate('/');
              }}
              type="large"
            >
              Home
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                navigate('/products');
              }}
              type="large"
            >
              Products
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                navigate('/rentals');
              }}
              type="large"
            >
              Rentals
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                navigate('/gallery');
              }}
              type="large"
            >
              Gallery
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

/*


     <BluelineIcon />
      <div className="no-page-container">
        <p className={styles.error}>Oops... 404 Error</p>
        <p className={styles['error-message']}>
          That page was not found, but try exploring our site and you might find
          what you are looking for.
        </p>
        <div className="no-page-btns">
          <Button
            onClick={e => {
              e.preventDefault();
              navigate('/');
            }}
            type="primary"
          >
            Home
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              navigate('/products');
            }}
            type="primary"
          >
            Products
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              navigate('/rentals');
            }}
            type="primary"
          >
            Rentals
          </Button>
        </div>
      </div>


*/
