import { Link } from 'react-router-dom';
import {
  APCLogo,
  BluelineLogo,
  ConcreteCoatingsLogo,
  SikaLogo,
  SurecreteLogo,
} from './Logos';
import styles from './FeaturedSection.module.css';

export default function FeaturedSection() {
  return (
    <div>
      <section id="section-featured" className={styles.featured}>
        <div className={styles.logos}>
          <Link to="https://www.alabamapigments.com/">
            <APCLogo />
          </Link>
          <Link to="https://www.buyblueline.net/">
            <BluelineLogo />
          </Link>
          <Link to="https://concretecoatingsinc.com/">
            <ConcreteCoatingsLogo />
          </Link>
          <Link to="https://usa.sika.com/">
            <SikaLogo />
          </Link>
          <Link to="https://surecretedesign.com/">
            <SurecreteLogo />
          </Link>
        </div>
      </section>
    </div>
  );
}
