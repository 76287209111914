const equipment = [
  {
    category: 'Skid Steers',
    manufacturer: 'Ditch Witch',
    model: 'SK900',
    description: 'Stand-on Skid Steer',
    summary:
      'With a stronger pound-per-pound punch than the competition, the Ditch Witch® SK900 puts in the muscle on landscaping and construction-grade jobs with a lower ground drive speed and more power directed where you need it most. The hard-working attachments.',
    image: './img/Rentals/machinery/sk900-4 (400x400).jpg',
    webpage:
      'https://www.ditchwitch.com/stand-on-skid-steers/mini-skid-steers/sk900/',
  },
  {
    category: 'Skid Steers',
    manufacturer: 'Ditch Witch',
    model: 'SK800',
    description: 'Stand-on Skid Steer',
    summary:
      'When your jobsites demand long-lasting durability and performance, you need the SK800—the perfect mini skid steer for a variety of jobs large and small.',
    image: './img/Rentals/machinery/sk800-5 (400x400).jpg',
    webpage:
      'https://www.ditchwitch.com/stand-on-skid-steers/mini-skid-steers/sk800/',
  },
  {
    category: 'Power Buggies',
    manufacturer: 'Canycom',
    model: 'SC75',
    description: 'Rubber Track Concrete Buggy',
    summary:
      'Canycom’s highly popular 16 cu ft SC75 Concrete Buggy with a rubber track undercarriage transports concrete with ease over rough terrain causing minimal impact to the ground surface.',
    image: './img/Rentals/machinery/sc75 (400x400).jpg',
    webpage: 'https://canycomsales.com/products/sc-series/sc75/',
  },
  {
    category: 'Power Buggies',
    manufacturer: 'Whiteman',
    model: 'WBH-16EF',
    description: 'Front Wheel Drive Power Buggy',
    summary:
      'This 16 cu ft buggy is a versatile performer offering foam-filled tires for maximum performance.',
    image: './img/Rentals/machinery/wbh-16ef-2 (400x400).jpg',
    webpage: 'https://www.multiquip.com/multiquip/WBH16EF.htm#no-jump',
  },
  {
    category: 'Excavators',
    manufacturer: 'Bobcat',
    model: 'E26',
    description: 'Compact Excavator',
    summary:
      'The Bobcat® E26 compact (mini) excavator features minimal tail swing to reduce the risk of damage to your machine and surrounding objects. Easily maneuver established lawns, crowded jobsites and sensitive surfaces with the compact size and minimal ground disturbance.',
    image: './img/Rentals/machinery/e26-4 (400x400).jpg',
    webpage:
      'https://www.bobcat.com/na/en/equipment/excavators/compact-excavators/e26',
  },
  {
    category: 'Trowels',
    manufacturer: 'Whiteman',
    model: 'J36H55',
    description: 'Walk Behind Trowel',
    summary:
      'Multiquips most popular mid-size trowel. 4-blade,36", Honda GX160 4.8HP, Min 60-115 Max RPM blade speed.',
    image: './img/Rentals/machinery/j36h55 (400x400).jpg',
    webpage: 'https://www.multiquip.com/multiquip/J36H55.htm',
  },
  {
    category: 'Trowels',
    manufacturer: 'Whiteman',
    model: 'J3036H55',
    description: 'Walk Behind Edging Trowel',
    summary:
      'The Multiquip edging trowel can easily be converted from 36 inches to 30 inches by simply removing the outer sections of the convertible ABS plastic rotating ring. 4-Blade,30"/36", Honda GX160, 4.8 HP, Min 60-115 Max RPM blade speed.',
    image: './img/Rentals/machinery/Whiteman-J3036H55 (400x400).png',
    webpage: 'https://www.multiquip.com/multiquip/J3036H55.htm',
  },
  {
    category: 'Compactors',
    manufacturer: 'Mikasa',
    model: 'MVC82VHW',
    description: 'Plate Compactor',
    summary:
      'Recognized as the industry standard for compactin4 granular soils and asphalt. 3080 lb. cf, Honda GX 160, 17.7 in. plate, water tank.',
    image: './img/Rentals/machinery/mvc82-compactor-2 (400x400).jpg',
    webpage: 'https://www.multiquip.com/multiquip/MVC82VHW.htm',
  },
  {
    category: 'Power Saws',
    manufacturer: 'Husqvarna',
    model: 'K770',
    description: '14" Power Cutter',
    summary:
      'A powerful all-round power cutter equipped with semi-automatic SmartTension™ system for optimal power transmission, minimum wear and maximum belt life. The light weight, outstanding power-to-weight ratio, the reliable start and exceptionally low vibrations mean less strain and maximised productivity.',
    image: './img/Rentals/machinery/k770 (400x400).jpg',
    webpage: 'https://www.husqvarna.com/us/power-cutters/k770/',
  },
  {
    category: 'Power Saws',
    manufacturer: 'Stihl',
    model: 'TS-420',
    description: '14" Power Cutter',
    summary:
      'The TS 420 is compact, lightweight, reliable, and features an anti-vibration system to maximize comfort. The TS-420 has a 14” cutting wheel that will handle cuts up to 4.9 inches in depth and includes the X2 air filtration system for a clean and smooth-running engine and virtually maintenance-free filters. This cut-off machine backs up its larger cutting capacity with a ton of usability features.',
    image: './img/Rentals/machinery/Stihl-TS-420-left-(400x400).JPEG',
    webpage:
      'https://www.stihlusa.com/products/cut-off-machines/professional-cut-off-machines/ts420/',
  },
  {
    category: 'Jackhammers',
    manufacturer: 'Hilti',
    model: 'TE 3000-AVR',
    description: 'Heavy Duty Electric Jackhmmer',
    summary:
      'Hilti’s new TE 3000 heavy-duty electric jackhammer with Active Vibration Reduction (AVR) delivers faster and virtually effortless concrete demolition to your jobsite – all you need is the tool, chisel and power cord.',
    image: './img/Rentals/machinery/hilti-3000-avr (400x400).jpg',
    webpage:
      'https://www.hilti.com/c/CLS_POWER_TOOLS_7124/CLS_DEMOLITION_HAMMER_BREAKER_SUB_7124/CLS_DEMOLITION_HAMMER_BREAKER_7124/r11668382#editorial',
  },
];

const stamps = [
  {
    name: 'Appalachian Flagstone',
    category: 'stamp set',
    image: './img/Rentals/stamps/appalachian-flagstone-2.jpg',
    includes: [
      { number: 9, type: 'rigid' },
      { number: 3, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: "2' Arizona Flagstone",
    category: 'stamp set',
    image: './img/Rentals/stamps/arizona-flagstone-2ft.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: "2' Ashlar Slate",
    category: 'stamp set',
    image: './img/Rentals/stamps/ashlar-slate-2-foot.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: "4' Ashlar Slate",
    category: 'stamp set',
    image: './img/Rentals/stamps/ashlar-slate-4-foot.png',
    includes: [
      { number: 5, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'XL Ashlar Slate',
    category: 'stamp set',
    image: './img/Rentals/stamps/XL ashlar slate 2.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Ashlar Tiles',
    category: 'stamp overlay set',
    image: './img/Rentals/stamps/overlay-ashlar-tiles.png',
    includes: [
      { number: 9, type: 'rigid' },
      { number: 3, type: 'floppy' },
    ],
  },
  {
    name: 'Baltic Cobblestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/baltic-cobblestone (200x200).png',
    includes: [
      { number: 9, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Basketweave Brick',
    category: 'stamp set',
    image: './img/Rentals/stamps/basketweave brick 2.png',
    includes: [
      { number: 10, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Belgium Cobblestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/belgium-cobblestone-set.png',
    includes: [
      { number: 5, type: 'rigid' },
      { number: 1, type: 'floppy' },
    ],
  },
  {
    name: 'Belgium Slate',
    category: 'Texture Skin Set',
    image: './img/Rentals/stamps/belgium-slate-texture.png',
    includes: [
      { number: 3, type: "4' x 4'" },
      { number: 3, type: "3' x 3'" },
      { number: 3, type: "1' x 1'" },
    ],
  },
  {
    name: '18" x 36" Bluestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/18x36-bluestone-3.png',
    includes: [
      { number: '*', type: 'rigid' },
      { number: '*', type: 'floppy' },
      { number: '*', type: 'touch up skin' },
    ],
  },
  {
    name: '9" x 54" Bluestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/9inx54in-bluestone.png',
    includes: [
      { number: '*', type: 'rigid' },
      { number: '*', type: 'floppy' },
      { number: '*', type: 'touch up skin' },
    ],
  },
  {
    name: 'Boardwalk 5in',
    category: 'stamp set',
    image: './img/Rentals/stamps/boardwalk-5-inch.png',
    includes: [
      { number: 7, type: 'rigid' },
      { number: 1, type: 'floppy' },
    ],
  },
  {
    name: '6" x 12" Brick Border',
    category: 'stamp set',
    image: './img/Rentals/stamps/6inx12in-brick-border.png',
    includes: [
      { number: 4, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'corner' },
    ],
  },
  {
    name: '8.5" Brick Border',
    category: 'stamp set',
    image: './img/Rentals/stamps/8.5in-brick-border.png',
    includes: [
      { number: 3, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'corner' },
    ],
  },
  {
    name: '8" Used Brick Border',
    category: 'stamp set',
    image: './img/Rentals/stamps/8in-used-brick-border.png',
    includes: [
      { number: 2, type: 'rigid' },
      { number: 1, type: 'floppy' },
    ],
  },
  {
    name: '10" Cobblestone Border',
    category: 'stamp set',
    image: './img/Rentals/stamps/10in-cobblestone-border.png',
    includes: [{ number: 3, type: 'various curved' }],
  },
  {
    name: "4' compass",
    category: 'artistic stamp',
    image: './img/Rentals/stamps/compass-4-foot.png',
    includes: [{ number: 1, type: 'custom overlay' }],
  },
  {
    name: 'Cornwall Stone Cobble',
    category: 'stamp set',
    image: './img/Rentals/stamps/cornwall-stone-cobble-set.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'English Yorkstone',
    category: 'stamp set',
    image: './img/Rentals/stamps/english yorkstone 1.png',
    includes: [
      { number: 9, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'European Fan Cobblestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/european-fan-cobblestone.png',
    includes: [
      { number: 6, type: 'rigid' },
      { number: 1, type: 'floppy' },
    ],
  },
  {
    name: 'Fieldstone',
    category: 'stamp set',
    image: './img/Rentals/stamps/fieldstone-1 (200x200).jpg',
    includes: [
      { number: 12, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Herringbone Brick',
    category: 'stamp set',
    image: './img/Rentals/stamps/herringbone-brick-set.png',
    includes: [
      { number: 7, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'New Herringbone Brick',
    category: 'stamp set',
    image: './img/Rentals/stamps/herringbone brick 2.png',
    includes: [
      { number: 10, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Italian Slate',
    category: 'texture skin set',
    image: './img/Rentals/stamps/italian slate texture 1.png',
    includes: [
      { number: 3, type: "4' x 4'" },
      { number: 3, type: "3' x 3'" },
      { number: 1, type: '18" x 18"' },
    ],
  },
  {
    name: 'Jumbo Stone',
    category: 'texture skin set',
    image: './img/Rentals/stamps/jumbo stone texture.png',
    includes: [
      { number: 3, type: "4' x 4'" },
      { number: 3, type: "3' x 3'" },
      { number: 1, type: '18" x 18"' },
    ],
  },
  {
    name: 'Large Stone Fan',
    category: 'stamp set',
    image: './img/Rentals/stamps/large-stone-fan (200x200).png',
    includes: [
      { number: 10, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Majestic Ashlar',
    category: 'stamp set',
    image: './img/Rentals/stamps/majestic ashlar 1.png',
    includes: [
      { number: 9, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Mayan Cobblestone',
    category: 'stamp set',
    image: './img/Rentals/stamps/mayan-cobblestone (200x200).png',
    includes: [
      { number: 10, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Picture Frame Tile',
    category: 'stamp set',
    image: './img/Rentals/stamps/picture-frame-tile.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: "3' Random Bluestone",
    category: 'stamp set',
    image: './img/Rentals/stamps/random-bluestone-3-foot-set.png',
    includes: [
      { number: 7, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Random Slate',
    category: 'stamp set',
    image: './img/Rentals/stamps/random-slate-set.png',
    includes: [{ number: 14, type: 'random shaped stamps' }],
  },
  {
    name: 'Royal Ashlar',
    category: 'stamp set',
    image: './img/Rentals/stamps/Royal Ashlar 2.png',
    includes: [
      { number: 8, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Runningbond Brick',
    category: 'stamp set',
    image: './img/Rentals/stamps/runningbond-brick.png',
    includes: [
      { number: 6, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: 'Sierra Slate',
    category: 'stamp set',
    image: './img/Rentals/stamps/sierra-slate-45-inch-set.png',
    includes: [
      { number: 5, type: 'rigid' },
      { number: 1, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: '12" x 12" Slate',
    category: 'stamp set',
    image: './img/Rentals/stamps/12inx12in-slate.png',
    includes: [
      { number: 13, type: 'rigid' },
      { number: 2, type: 'floppy' },
      { number: 1, type: 'touch up skin' },
    ],
  },
  {
    name: "3' Sundial",
    category: 'Artistic stamp',
    image: './img/Rentals/stamps/sun-4-foot.png',
    includes: [{ number: 1, type: 'custom overlay' }],
  },
  {
    name: 'Truncated Dome',
    category: 'stamp set',
    image: './img/Rentals/stamps/truncated-dome.png',
    includes: [
      { number: 1, type: 'rigid' },
      { number: 1, type: 'floppy' },
    ],
  },
  {
    name: '11.5" Woodplank',
    category: 'stamp set',
    image: './img/Rentals/stamps/woodplank-set-11.5-inch.png',
    includes: [{ number: 12, type: 'various lengths' }],
  },
  {
    name: '12" Woodplank',
    category: 'stamp set',
    image: './img/Rentals/stamps/woodplank-set-12-inch.png',
    includes: [
      { number: 3, type: '36" rigid' },
      { number: 1, type: '36" floppy' },
      { number: 3, type: '48" rigid' },
      { number: 1, type: '48" floppy' },
      { number: 4, type: '72" rigid' },
    ],
  },
];

export { stamps, equipment };
