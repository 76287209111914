// Contact form from Contact Section on Home page.
import { useState } from 'react';
import styles from './ContactForm.module.css';

export default function ContactForm() {

  const [status, setStatus] = useState('Submit');

  async function handleSubmit(e) {
    e.preventDefault();

    setStatus('Sending...');

    const { firstname, lastname, email, phoneNumber, message } = e.target.elements;

    let details = {
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      phoneNumber: phoneNumber.value,
      message: message.value,
    };

    console.log(details);

    let response = await fetch('https://buyblueline.net/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(details),
    });
    setStatus('Submit');

    let result = await response.json();
    alert(result.status);
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <p className={styles.formTitle}>Contact us</p>
      {/* <p className="message">  
        We love getting feedback, questions, and hearing what you have to say!
      </p> */}
      <div className={styles.flex}>
        <label htmlFor="firstname">
          <input
            className={styles.input}
            id="firstname"
            required={true}
            type="text"
            autoComplete="given-name"
          />
          <span>Firstname</span>
        </label>
        <label htmlFor="lastname">
          <input
            className={styles.input}
            id="lastname"
            required={true}
            type="text"
            autoComplete="family-name"
          />
          <span>Lastname</span>
        </label>
      </div>
      <label htmlFor="email">
        <input
          className={styles.input}
          id="email"
          required={true}
          type="email"
          autoComplete="email"
        />
        <span>Email</span>
      </label>
      <label htmlFor="phoneNumber">
        <input
          className={styles.input}
          id="phoneNumber"
          required={true}
          type="phone"
          autoComplete="tel"
        />
        <span>Phone</span>
      </label>
      <label htmlFor="message">
        <textarea
          className={styles.input}
          id="message"
          name="message"
          required={true}
          type="message"
          rows={3}
          autoComplete="off"
        />
        <span>Enter message...</span>
      </label>
      <button className={styles.submit} type="submit">
        Send message
      </button>
    </form>
  );
}