import { Link } from 'react-router-dom';
import { APCLogo, BluelineLogo, SurecreteLogo } from '../components/Logos.js';
import { surecrete, alabamaPigmentCo } from '../db/productData.js';
import { blueline } from '../db/productData.js';
import Card from '../components/Card.js';
import styles from './Products.module.css';

const releaseMax = blueline[0];
const accuPac = blueline[1];

export default function Products() {

  function displayColorCharts(productArr) {
    return productArr.map(product => (
      <Link to={product.url} key={product.product} className={styles.link}>
        <Card type={'colorSamples'}>
          <img
            className={styles.image}
            src={product.image}
            alt={product.product}
          />
          <h4 className={`${styles.productName} center`}>{product.product}</h4>
        </Card>
      </Link>
    ));
  }

  function BluelineProducts() {
    return (
      <>
        <h2 className="heading-secondary heading-dark center-text">{releaseMax.product}</h2>
        <div
          className="flex margin-bottom-md"
          id="color-grid"
        >
          {releaseMax.colors.map(color => (
            <Card key={color.color} type={'product'}>
              <img
                className={styles.image}
                src={color.image}
                alt={color.name}
              />
              <div className={styles.summary}>
                <span className={styles.color}>{color.color}</span>
              </div>
            </Card>
          ))}
        </div>

        <p className="paragraph">{releaseMax.description}</p>
        <h3 className="heading-tertiary">Features & Benefits</h3>
        <ul className="list">
          {releaseMax.features.map(feature => (
            <li key={feature} className="list-item">
              {feature}
            </li>
          ))}
        </ul>
        <h3 className="heading-tertiary">Tips</h3>
        <ul className="list">
          {releaseMax.tips.map(tip => (
            <li key={tip} className="list-item">
              {tip}
            </li>
          ))}
        </ul>
        <h3 className="heading-tertiary">Packaging & Coverage</h3>
        <p className="paragraph margin-bottom-lg">{releaseMax.packaging}</p>

        <h2 className="heading-secondary heading-dark center-text">{accuPac.product}</h2>
        <div className="flex margin-bottom-md">
          {accuPac.colors.map(color => (
            <Card key={color.color} type={'product'}>
              <img
                className={styles.image}
                src={color.image}
                alt={color.name}
              />
              <div className={styles.summary}>
                <span className={styles.color}>{color.color}</span>
                <p className={styles.usage}>{color.coating}</p>
              </div>
            </Card>
          ))}
        </div>

        <p className="paragraph">{accuPac.description}</p>
        <h3 className="heading-tertiary">Materials</h3>
        <p className="paragraph">{accuPac.materials}</p>
        <h3 className="heading-tertiary">Packaging</h3>
        <p className="paragraph">{accuPac.packaging}</p>
        <h3 className="heading-tertiary">Mix Design</h3>
        <ul className="list">
          {accuPac.mixDesign.map(item => (
            <li key={item} className="list-item">
              {item}
            </li>
          ))}
        </ul>
        <h3 className="heading-tertiary">
          Additional Items for the Contractor
        </h3>
        <ul className="list">
          {accuPac.tips.map(tip => (
            <li key={tip} className="list-item">
              {tip}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <main className={styles.products}>
      <div className="page-header center-text">
        <span className="heading-primary heading-light">
          All Products
        </span>
      </div>

      <div className="container">
        <div className={styles.logo}>
          <BluelineLogo />
        </div>
        <BluelineProducts />
      </div>

      <h2 className="heading-secondary heading-dark center-text">Available products from our partners</h2>

      <div className="container">
        <div className={styles.logo}>
          <APCLogo />
        </div>

        <div className="flex">{displayColorCharts(alabamaPigmentCo)}</div>
      </div>

      <div className="container">
        <div className={styles.logo}>
          <SurecreteLogo />
        </div>
        <div className="flex">{displayColorCharts(surecrete)}</div>
      </div>
    </main>
  );
}
