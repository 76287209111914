import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconContext, List, X } from '@phosphor-icons/react';
// import ContactNav from './ContactNav';
import AppNav from './AppNav';
import styles from './Header.module.css';

export default function Header() {
  const [openNav, setOpenNav] = useState(false);

  function handleOpenNav() {
    setOpenNav(true);
  }
  function handleCloseNav() {
    setOpenNav(false);
  }

  // `header ${openNav ? 'nav-open' : ''}`
  return (
    <IconContext.Provider
      value={{
        color: '#2419b2',
        size: 32,
        weight: 'regular',
      }}
    >
      <header
        className={`${styles.header} ${styles[openNav ? 'navOpen' : '']}`}
      >
        <div className={styles.logoContainer}>
          <Link to="/">
            <img
              className={styles.logo}
              alt="Blueline logo"
              src="img/blueline-logo-horizontal-plain-black.png"
            />
          </Link>
        </div>

        <div className={styles.navContainer}>
          {/* <ContactNav /> */}
          <AppNav openNav={openNav} handleCloseNav={handleCloseNav} />

          <button className={styles.btn}>
            <List
              className={styles.icon}
              name="menu-outline"
              onClick={() => handleOpenNav()}
            />
            <X
              className={styles.icon}
              name="close-outline"
              onClick={() => handleCloseNav()}
            />
          </button>
        </div>
      </header>
    </IconContext.Provider>
  );
}

/* 
          <ion-icon
            className={styles.icon}
            name="menu-outline"
            onClick={() => handleOpenNav()}
          ></ion-icon>

onClick={() => handleCloseNav()}
          <ion-icon
            className={styles.icon}
            name="close-outline"
            
          ></ion-icon>

*/ 