import React from 'react';
import Hero from '../components/Hero';
import ContactSection from '../components/ContactSection';
import styles from './Home.module.css'

export default function Home() {
  return (
    <div>
      <Hero />
      <section id="about">
        <div className="container">
          <h2 className="heading-secondary heading-dark center-text">
            Experience you can count on
          </h2>
          <div className="grid grid--2-cols grid-center-v">
            <div>
              <p className="paragraph">
                Welcome to Blueline Decorative Concrete Supply – your
                one-stop-shop for premium decorative concrete products and
                supplies. We are passionate about helping contractors and DIY
                enthusiasts alike elevate their concrete projects to the next
                level.
              </p>
              <p className="paragraph">
                With a wide range of options including stamps, stains, sealers,
                dyes, and more, we have everything you need to bring your vision
                to life. Our experts are dedicated to providing exceptional
                customer service and support, ensuring that you have all the
                knowledge and resources necessary to tackle any project with
                confidence.
              </p>
              <p className="paragraph">
                At Blueline, we prioritize quality above all else. That's why we
                only work with the most trusted manufacturers in the industry to
                provide you with the finest materials available. Our products
                are designed to be durable, long-lasting, and visually stunning,
                so you can be confident that your finished project will exceed
                your expectations.
              </p>
              <p className="paragraph">
                Whether you're a seasoned professional or just starting out,
                Blueline Decorative Concrete Supply has the products, expertise,
                and support you need to achieve your desired results. Visit our
                website or stop by our store to learn more and experience the
                Blueline difference for yourself.
              </p>
            </div>
            <div className={styles.composition}>
              <img
                className={`${styles.photoComposition} ${styles.photo1}`}
                src="./img/gallery/composition-1.jpg"
                alt=""
              />
              <img
                className={`${styles.photoComposition} ${styles.photo2}`}
                src="./img/gallery/composition-2.JPEG"
                alt=""
              />
              <img
                className={`${styles.photoComposition} ${styles.photo3}`}
                src="./img/gallery/composition-3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <ContactSection />
    </div>
  );
}
